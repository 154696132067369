<template>
  <div>
    <div class="app-container">
      <div class="services">
        <div 
          class="sitem" 
          v-for="(item, i) in services" 
          :key="i"
          @click="route(item.id, item.path)"
        >
          <v-img
            :src="item.img"
            height="100%"
            width="100%"
            gradient="rgba(0,0,0,.7),rgba(0,0,0,.7)"
            class="img"
          ></v-img>
          <div class="txt" v-html="item.txt"></div>
          <div class="txt-desc"><v-icon left color="#ADFF2F">mdi-arrow-right-bold-box</v-icon>{{item.desc}}</div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  data: () => ({
    services: [
      {id: 1, img: 'img/bg/bg_truck_1.jpg', txt: 'Курсы международников <br> Курсы дальнобойщиков <br> Разовые занятия на тягаче <br> ADR. Опасные грузы', path: '/courses', desc: 'подробнее'},
      {id: 2, img: 'img/bg/bg_reviews.jpg', txt: 'отзывы', path: '/reviews', desc: 'перейти'},
      {id: 3, img: 'img/bg/bg_pdd.jpg', txt: 'пдд он-лайн', path: 'https://pdd.by/tasks/online/', desc: 'перейти'}
    ],
  }),
  methods: {
    route(id, path) {
      if (id == 3) {
        window.open(path)
      } else {
        if(this.$route.path !== path) this.$router.push(path)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .services {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 70px 0;
    .sitem {
      height: 250px;
      width: 350px;
      position: relative;
      margin: 7px;
      cursor: pointer;
      overflow: hidden;
      &:hover .img {
        transform: scale(1.2);
      }
      &:hover .txt-desc {
        opacity: 1;
      }
      &:hover .txt {
        opacity: 0.5;
      }
      .img {
        transition: all .3s;
      }
      .txt {
        position: absolute;
        left: 25px;
        bottom: 25px;
        text-transform: uppercase;
        color: #ffffff;
        font-weight: bold;
        transition: all .3s;
      }
      .txt-desc {
        opacity: 0;
        position: absolute;
        color: #ADFF2F;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-transform: uppercase;
        transition: all .3s;
        i {
          margin-bottom: 4px;
        }
      }
    }
  }
</style>
