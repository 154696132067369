<template>
  <div>
    <div class="banner">
      <v-img
        :src="getContent.topBanner"
        :height="getContent.topCarouselHeight"
        gradient="rgba(0,0,0,.5), rgba(0,0,0,.5)"
        :position="getContent.topBgPosition"
      ></v-img>
    </div>
    <Carousel :height="getContent.topCarouselHeight"/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Carousel from '@/components/TopCarousel.vue'
export default {
  components: {Carousel},
  computed: {
    ...mapGetters(['getContent'])
  }
}
</script>

<style lang="scss" scoped>
  .banner {
    position: relative;
  }
</style>
