<template>
  <div class="cost bgrey">
    <div class="cost-header hgrey">
      <h2 class="title">Стоимость обучения</h2>
    </div>
    <div class="app-container">
      <div class="text" v-if="getCost().length != 0" v-html="cost.description.replace(/\n/g, '<br>')"></div>
    </div>
    <div class="app-container bg">
      <v-img
        src="img/bg/bg_cost_1.png"
        class="cbg1"
        max-width="350"
      ></v-img>
      <v-img
        src="img/bg/bg_cost_2.png"
        class="cbg2"
        max-width="170"
      ></v-img>
      <div class="cost-wrapper">
        <div class="circular" v-if="getCost().length == 0">
          <v-progress-circular
            indeterminate
            color="green"
            size="100"
            width="5"
          >загрузка</v-progress-circular>
        </div>
        <div class="cost-wrap" v-if="getCost().length != 0">
          <div class="cost-selector grid" ref="costSelect">
            <v-card
              max-width="500"
            >
            <v-list>
              <v-list-item-group 
                v-model="model"
                mandatory
                color="#FF4500"
              >
                <v-list-item
                  v-for="(item, i) in getCost()"
                  :key="i"
                  @click="catClick(item.id)"
                >
                  <v-icon small left v-if="item.id == id || item.id == (model + 1)">mdi-check-bold</v-icon>
                  <v-list-item-content>
                    <v-list-item-title >Категория «{{item.cat}}»</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
          </div>
          <div class="cost-content" ref="cost">
            <div class="cost-logo-wrap">
              <div class="cost-logo-txt">
                <strong>Категория «{{cost.cat}}»</strong>
              </div>
              <div class="cost-logo">
                <img :src="cost.img" alt="">
                <div class="cl-wrap">
                  <div class="clw-item"></div>
                  <div class="clw-item"></div>
                </div>
              </div>
              <v-btn 
                small
                @click="openMailDialog(cost.cat)"
                color="#4caf50"
                dark
              >
                <v-icon small left>mdi-clipboard-edit</v-icon>
                <strong>Записаться</strong>
              </v-btn>
            </div>
            <div class="cost-txt" ref="tab">
              <table>
                <tbody>
                  <tr class="cat-tr">
                    <td>
                      <strong>Категория «{{cost.cat}}»</strong>
                    </td>
                    <td>
                      <v-btn 
                        small
                        @click="openMailDialog(cost.cat)"
                        color="#4caf50"
                        dark
                      >
                        <v-icon small left>mdi-clipboard-edit</v-icon>
                        <strong>Записаться</strong>
                      </v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td>срок обучения:</td>
                    <td>{{cost.term}}</td>
                  </tr>
                  <tr>
                    <td><strong>Витебск</strong></td>
                    <td><strong>Орша</strong></td>
                  </tr>
                  <tr>
                    <td colspan="2">Обучение на:</td>
                  </tr>
                  <tr>
                    <td v-html="cost.car_v"></td>
                    <td v-html="cost.car_o"></td>
                  </tr>
                  <tr>
                    <td colspan="2">Стоимость:</td>
                  </tr>
                  <tr>
                    <td><strong><span v-if="cost.pV">от</span> {{cost.cost_v}} BYN</strong></td>
                    <td><strong><span v-if="cost.pO">от</span> {{cost.cost_o}} BYN</strong></td>
                  </tr>
                  <tr>
                    <td colspan="2">Рассрочка на: {{ras}}</td>
                  </tr>
                  <tr>
                    <td>в месяц: {{ras_v.toFixed(2)}} BYN</td>
                    <td>в месяц: {{ras_o.toFixed(2)}} BYN</td>
                  </tr>
                </tbody>
              </table>
              <v-slider
                class="slider"
                v-model="mounth"
                :tick-labels="ticksLabels"
                :max="3"
                step="1"
                thumb-label="always"
                ticks="always"
                tick-size="4"
                color="green"
                track-color="#FF4500"
                label="рассрочка на"
              >
              <template v-slot:thumb-label="{ value }">
                {{ ras_array[value] }}
              </template>
              </v-slider>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sertifikat grid">
      <div class="s-img">
        <div class="s-hover" @click="show(index = 0)">
          <v-icon 
            color="#ffffff"
            size="50"
          >mdi-magnify</v-icon>
        </div>
        <v-img
          class="s-img"
          :src="cost.img_s"
          max-width="100"
        ></v-img>
      </div>
      <div class="s-txt">
        <p><strong>сертификат соответствия</strong></p>
        <p><span>на оказание услуг по подготовку водителей механических транспортных средств <br> <strong>категории «{{cost.cat}}»</strong></span></p>
      </div>
    </div>
     <v-dialog
      v-model="dialog"
      persistent
      max-width="350"
    >
      <v-card class="send-mail">
        <v-form ref="mail">
          <v-text-field
            solo
            :value="`Категория «${mail.cat}»`"
            disabled
          ></v-text-field>
          <v-select
            :items="items"
            v-model="mail.city"
            label="Выберите город"
            solo
            :rules="mailRules"
          ></v-select>
          <v-text-field
            label="Ваше имя"
            solo
            v-model="mail.name"
            :rules="mailRules"
          ></v-text-field>
          <v-text-field
            label="Номер телефона"
            solo
            v-model="mail.phone"
            v-mask="mask"
            masked
            type="tel"
            :rules="mailRules"
          ></v-text-field>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              small
              @click="cancelMail()"
            >
              Отмена
            </v-btn>
            <v-btn
              small
              @click="sendMail"
            >
              Записаться
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
     <v-dialog
        v-model="dialogOk"
        max-width="290"
      >
      <v-card class="mail-ok">
        Ваша заявка принята
        <div class="mail-ok-btn">
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialogOk = false"
          >
            <strong>ok</strong>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <div class="top-btn" v-if="catbtn">
      <v-btn small dark @click="topBtnClick">выбрать категорию</v-btn>
    </div>
    <vue-easy-lightbox
      :visible="visible"
      :imgs="cost.img_s"
      :index="index"
      @hide="handleHide"
    ></vue-easy-lightbox>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {mask} from 'vue-the-mask'
export default {
  props: {
    loadingWindow: Function
  },
  directives: {mask},
  data: () => ({
    visible: false,
    dialog: false,
    dialogOk: false,
    mask: '+375-(##)-###-##-##',
    mail: {},
    id: null,
    model: 1,
    mounth: 0,
    ras_array: [4,6,8,12],
    ticksLabels: ['мес','мес','мес','мес'],
    catbtn: false,
    arr: [{
      id: null,
      cat:'',
      term:'',
      car_v:'',
      car_o:'',
      cost_v: 0,
      cost_o: 0,
      img:'',
      description: ''
    }],
    index: null,
    items: ['Витебск', 'Орша'],
    city: null,
    mailRules: [
      v => !!v || 'Пожалуйста, заполните поле!'
    ],
  }),
  computed: {
    cost() {
      if (this.id == null) {
        if (this.getCost().length == 0 ) {
          return this.arr
        } else {
          return this.getCost()[1]
        }
      } else {
        return this.getCost().find(c => c.id == this.id)
      }
    },
    ras() {
      if(this.mounth == 0) return '4 месяца'
      if(this.mounth == 1) return '6 месяцев'
      if(this.mounth == 2) return '8 месяцев'
      if(this.mounth == 3) return '12 месяцев'
    },
    ras_v() {
      if(this.mounth == 0) return this.cost.cost_v / 4
      if(this.mounth == 1) return this.cost.cost_v / 6
      if(this.mounth == 2) return this.cost.cost_v / 8
      if(this.mounth == 3) return this.cost.cost_v / 12
    },
    ras_o() {
      if(this.mounth == 0) return this.cost.cost_o / 4
      if(this.mounth == 1) return this.cost.cost_o / 6
      if(this.mounth == 2) return this.cost.cost_o / 8
      if(this.mounth == 3) return this.cost.cost_o / 12
    },
  },
  methods: {
    catClick(id) {
      this.id = id
      if(window.innerWidth <= 540) {
        this.$refs.cost.scrollIntoView({block: 'center', behavior: 'smooth'})
      }
    },
    topBtnClick() {
      this.$refs.costSelect.scrollIntoView({block: 'center', behavior: 'smooth'})
    },
    handleHide() {
      this.visible = false
    },
    show() {
      this.visible = true
    },
    ...mapGetters(['getCost']),
    ...mapActions(['__sendMail']),
    openMailDialog(cat) {
      let y = new Date().getFullYear()
      let m = new Date().getMonth() + 1
      if(m < 10) m = '0' + m
      let d = new Date().getDate()
      if(d < 10) d = '0' + d
      let h = new Date().getHours()
      let mm = new Date().getMinutes()
      let date = `${d}.${m}.${y} - ${h}:${mm}`
      this.mail.date = date
      this.mail.cat = cat
      this.dialog = true
    },
    async sendMail() {
      if (this.$refs.mail.validate()) {
        this.loadingWindow(true)
        let res = await this.__sendMail(this.mail)
        if(res.sendMail == 'ok') {
          this.loadingWindow(false)
          this.cancelMail()
          this.dialogOk = true
        }
      }
    },
    cancelMail() {
      this.dialog = false
      this.mail = {}
      this.$refs.mail.reset()
    }
  },
  mounted() {
    document.onscroll = () => {
      if(window.innerWidth <= 540) {
        if(this.$refs.tab.getBoundingClientRect().top <= 250 && this.$refs.tab.getBoundingClientRect().bottom >= 395) {
          this.catbtn = true
        } else {
          this.catbtn = false
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .top-btn {
    position: fixed;
    bottom: 7px;
    z-index: 5;
    color: #ffffff;
    left: 50%;
    transform: translateX(-50%);
  }
  .cat-tr {
    display: none;
  }
  .op1 {
    opacity: 1;
  }
  .op2 {
    opacity: 0;
  }
  .cost {
    padding: 30px 0;
    position: relative;
    .text {
      text-align: center;
      max-width: 1000px;
      min-height: 75px;
      margin: 0 auto;
      display: grid;
      align-content: center;
    }
  }
  .bg {
    position: relative;
    .cbg1 {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    .cbg2 {
      position: absolute;
      left: 19px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  @media only screen and (max-width: 1110px) {
    .bg {
      .cbg1 {
        display: none;
      }
      .cbg2 {
        display: none;
      }
    }
  }
  .cost-wrapper {
    max-width: 1000px;
    margin: 30px auto 50px;
  }
  .circular {
    display: grid;
    height: 30vh;
    align-items: center;
    justify-items: center;
    text-transform: uppercase;
  }
  .slider {
    margin-top: 35px;
    border-bottom: 1px solid #C0C0C0;
  }
  .cost-selector {
    align-items: center;
  }
  .cost-content {
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
    .cost-logo-wrap {
      text-align: center;
      button {
        margin-top: 9px;
      }
    }
  }
  .cost-wrap {
    display: grid;
    grid-template-columns: 2fr 7fr;
    align-items: center;
  }
  @media only screen and (max-width: 795px) {
    .cost-content {
      display: block;
    }
    .cost-wrap {
      grid-gap: 10px;
    }
    .cost-logo-wrap {
      display: none;
    }
    .cat-tr {
      display: table-row;
    }
  }
  @media only screen and (max-width: 540px) {
    .cost-wrap {
      grid-template-columns: 1fr;
      align-items: center;
    }
    .cost {
      .text {
        display: none;
      }
    }
  }
  .cost-txt {
    z-index: 1;
    /* background-color: #fcfcfc; */
    table {
      width: 100%;
      text-align: center;
    }
    td {
      border: 1px solid #C0C0C0;
      border-radius: 3px;
      padding: 3px 5px;
      background-color: #ffffff;
    }
  }
    .cost-logo {
      display: grid;
      align-items: center;
      justify-items: center;
      position: relative;
      width: 210px;
      height: 210px;
      margin: 0 auto;
    }
    .cost-logo-txt {
      text-align: center;
      font-size: 1.5rem;
    }
    .cost-logo img {
      max-width: 150px;
      z-index: 1;
    }
    .cl-wrap {
      position: absolute;
      width: 190px;
      height: 190px;
      border: 7px solid #696969;
      border-radius: 50%;
      animation: rot 45s linear 0s infinite;
      @keyframes rot {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
    .clw-item:nth-child(1) {
      position: absolute;
      width: 15px;
      height: 205px;
      background-color:  #f7f7f7;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .clw-item:nth-child(2) {
      position: absolute;
      width: 15px;
      height: 205px;
      background-color:  #f7f7f7;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(90deg);
    }
    
    .sertifikat {
      max-width: 500px;
      margin: 0px auto;
      grid-template-columns: repeat(2, auto);
      gap: 10px;
      align-items: center;
      padding: 10px 15px;
      background-color: #ffffff;
      border-radius: 3px;
      border: 1px solid #c0c0c0;
      .s-img {
        cursor: pointer;
        position: relative;
        border-radius: 3px;
        .s-hover {
          border-radius: 3px;
          position: absolute;
          z-index: 1;
          width: 100%;
          height: 100%;
          transition: all .3s;
          i {
            opacity: 0;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          &:hover {
            background-color: rgba(76, 175, 80, .7);
            i {
              opacity: 1;
            }
          }
        }
      }
      .s-txt {
        color: #595959;
        p {
          &:nth-child(1) {
            text-transform: uppercase;
          }
        }
      }
    }
    .send-mail {
      padding: 15px;
    }
    .mail-ok {
      padding: 15px;
      font-weight: bold;
      text-align: center;
      .v-card__actions {
        button {
          margin: 0 auto;
        }
      }
    }
</style>
