<template>
  <div class="tizer-wrapper bgrey">
    <div class="app-container">
      <div class="tizer grid">
        <div class="tizer-item" v-for="(item, i) in getContent.topTizer" :key="i">
          <div class="tizer-item-wrap grid">
            <div class="tizer-img">
              <v-img
                :src="item.img"
                max-width="100px"
              ></v-img>
            </div>
            <p><strong v-html="item.heading"></strong></p>
            <p v-html="item.text"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  computed: {
    ...mapGetters(['getContent'])
  }
}
</script>

<style lang="scss" scoped>
  .tizer-wrapper {
    padding: 55px 0;
    .tizer {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      grid-gap: 10px;
      .tizer-item-wrap {
        align-items: center;
        justify-items: center;
        text-align: center;
        .tizer-img {
          margin-bottom: 15px;
        }
      }
    }
  }
</style>
