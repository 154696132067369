<template>
  <div>
    <div class="contacts-wrap bgrey">
      <div class="contacts-header hgrey"><h2 class="title">Контакты</h2></div>
      <div class="app-container">
        <div class="contacts">
          <v-container fluid>
            <v-select
              outlined
              dense
              class="select"
              :items="cityList"
              v-model="valueCity"
              label="Выберите город"
              color="#4caf50"
              item-color="deep-orange darken-1"
              @input="resetAddress"
            ></v-select>
          </v-container>
        </div>
        <div class="home-address bg">
          <div class="ha-item">
            <div class="addrr">
              <v-icon left>mdi-card-account-details</v-icon>Наши адреса:<br>
              <br>
              <v-icon left small>mdi-map-marker-radius</v-icon>г. {{city.text}}<br>
              <div v-for="(item, a) in address" :key="a + '-address'">
                <v-icon left small>mdi-map</v-icon>{{item.address}} <br>
                <v-icon left small v-if="item.worktime != null && item.worktime != ''">mdi-clock-outline</v-icon> {{item.worktime}}
              </div>
            </div>
            <div class="addrr">
              <v-icon left>mdi-phone-classic</v-icon>Наши телефоны:<br>
              <br>
              <div v-for="(item, p) in phone" :key="p + '-phone'"><v-icon left small>mdi-phone</v-icon><a :href="`tel:${item.phone}`">{{item.phone}}</a><br></div>
            </div>
          </div>
          <div class="ha-item">
            <v-select
              color="#4caf50"
              item-color="deep-orange darken-1"
              dense
              :items="addressList"
              label="Выберите адрес"
              v-model="valueAddress"
              @input="visible != valueAddress ? visible = 0 : false"
            ></v-select>
            <v-progress-circular
              v-if="visible == 0 || map.map == 'about:blank'"
              class="load"
              indeterminate
              rotate="5"
              size="50"
              width="5"
              color="#4caf50"
            ></v-progress-circular>
            <iframe @load="visible = valueAddress" :src="map.map" width="100%" height="250" frameborder="0"></iframe>
            <div class="nomap" v-if="map.map == null || map.map == ''">нет карты :(</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  data: () => ({
    id: null,
    valueCity: 1,
    valueAddress: null,
    visible: 0,
  }),
  computed: {
    cityList() {
      return this.getCity()
    },
    city() {
      if (this.getCity().length == 0) {
        return {text: 'загрузка...'}
      } else {
        return this.getCity().find(c => c.value == this.valueCity)
      }
    },
    addressList() {
      let arr = []
      let index = 0
      this.getAddress().filter(a => a.city_id == this.valueCity).map(a => {
        arr.push({text: a.address, value: a.id})
        if(index == 0) this.valueAddress = a.id
        index++
      })
      return arr
    },
    address() {
      return this.getAddress().filter(a => a.city_id == this.valueCity)
    },
    phone() {
      return this.getPhone().filter(p => p.city_id == this.valueCity)
    },
    map() {
      if (this.getAddress().length != 0) {
        return this.getAddress().find(a => a.city_id == this.valueCity && a.id == this.valueAddress)
      } else {
        return {map: 'about:blank'}
      }
    },
  },
  watch: {
    valueCity: function () {
      this.visible = 0
    }
  },
  methods: {
    ...mapGetters(['getCity','getAddress', 'getPhone']),
    resetAddress() {
      this.valueAddress = this.valueCity
    },
  }
}
</script>

<style lang="scss" scoped>
  .nomap {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-transform: uppercase;
    font-weight: bold;
    color: green;
  }
  .select {
    max-width: 350px;
    margin: 15px auto 0;
  }
  .contacts-wrap {
    padding: 30px 0;
    position: relative;
  }
  .home-address {
    display: grid;
    grid-template-columns: 2fr 3fr;
    align-items: center;
    grid-gap: 25px;
    margin: 25px 0;
    .ha-item {
      a {
        text-decoration: none;
        color: rgba(0, 0, 0, 0.87);
      }
      &:nth-child(2) {
        position: relative;
        .load {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  .addrr {
    &:nth-child(1) {
      margin-bottom: 45px;
    }
  }
  @media only screen and (max-width: 795px) {
    .home-address {
      display: block;
    }
    .addrr {
      &:nth-child(1) {
        margin-bottom: 17px;
        padding-bottom: 15px;
        border-bottom: 1px dotted rgb(167, 167, 167);
      }
      &:nth-child(2) {
        margin-bottom: 25px;
        padding-bottom: 15px;
      }
    }
  }
</style>
