<template>
  <div>
    <Banner/>
    <Tizer/>
    <Services id="curs"/>
    <Cost id="cost" :loadingWindow="loadingWindow"/>
    <Gallary id="avto"/>
    <Contacts id="cont"/>
  </div>
</template>

<script>
import Banner from '@/components/Banner.vue'
import Cost from '@/components/Cost.vue'
import Tizer from '@/components/Tizer.vue'
import Services from '@/components/Services.vue'
import Gallary from '@/components/Gallary.vue'
import Contacts from '@/components/Contacts.vue'
export default {
  props: {
    loadingWindow: Function
  },
  name: 'home',
  components: { 
    Banner,
    Cost, 
    Tizer, 
    Services, 
    Gallary, 
    Contacts,
  },
  mounted() {

    if(this.$route.hash) {
      this.$nextTick(function() {
        let block = document.querySelector(`${this.$route.hash}`)
        if (block != null) {
          document.querySelector(`${this.$route.hash}`).scrollIntoView({block: 'center', behavior: 'auto'})
        } else {
          window.scrollTo({top: 0})
        }
      })
    } else {
      window.scrollTo({top: 0})
    }
    
  }
}
</script>

<style lang="scss" scoped>
  
</style>
