<template>
  <div class="gallary-wrap">
    <div class="app-container">
      <div class="title-container">
        <div><h2 class="title">Автопарк</h2></div>
        <div class="filters">
          <!-- <v-btn small class="filter" :class="{ active: currentFilter === 'all' }" @click="setFilter('all')">все</v-btn> -->
          <v-btn small class="filter" :class="{ active: currentFilter === 'car' }" @click="setFilter('car')">легковые</v-btn>
          <v-btn small class="filter" :class="{ active: currentFilter === 'truck' }" @click="setFilter('truck')">грузовые</v-btn>
          <v-btn small class="filter" :class="{ active: currentFilter === 'bus' }" @click="setFilter('bus')">автобусы</v-btn>
        </div>
      </div>
      
      <transition-group class="projects" name="bounce">
        <div class="project-item" key="gallary360" v-if="len > 0">
          <div class="project" v-if="currentFilter === cat">
            <v-img @click="img360(currentFilter)" width="100%" class="prj-image" contain src="img/png/360.png"></v-img>
          </div>
        </div>
        <div class="project-item" v-for="(img, i) in imgs" :key="'gallary' + i">
          <div class="project" v-if="currentFilter === cat || currentFilter === 'all'">
            <v-img width="100%" class="prj-image" :src="img" @click="show(i)"></v-img>
          </div>
        </div>
      </transition-group>
    </div>

    <v-dialog
      v-model="dialog"
      max-width="800"
    >
      <v-card class="card360">
        <v-btn
          class="close"
          color="red"
          fab
          dark
          small
          @click="close()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <vue-three-sixty 
          :amount="len"
          imagePath="img/carpark/"
          :fileName="cat+'360-{index}.jpeg'"
          spinReverse
          scrollImage
          v-if="dialog"
        />
        
      </v-card>
    </v-dialog>

    <vue-easy-lightbox
      :visible="visible"
      :imgs="imgs"
      :index="index"
      @hide="handleHide"
    ></vue-easy-lightbox>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  data: () => ({
		currentFilter: 'car',
    index: null,
    cat: null,
    dialog: false,
    visible: false,
    len: null
	}),
  computed: {
    ...mapGetters(['getImages']),
    imgs: function () {
      let arr = []
      if (this.currentFilter != 'all') {
        this.cat = this.currentFilter
        this.getImages.filter(img => img.cat == this.currentFilter).map(i => {
          arr.push(i.path + i.filename)
        })
        this.len = this.getImages.filter(img => img.cat == this.currentFilter+'360').length
      } else {
        this.getImages.map(i => {
          if (i.cat != 'car360' && i.cat != 'truck360' && i.cat != 'bus360') arr.push(i.path + i.filename)
        })
      }
      return arr
    }
  },
	methods: {
    close() {
      this.dialog = false
    },
    handleHide() {
      this.visible = false
    },
    show(i) {
      this.index = i
      this.visible = true
    },
		setFilter: function(filter) {
      this.currentFilter = filter
    },
    img360(currentFilter) {
      this.dialog = true
    }
  },
}
</script>

<style lang="scss" scoped>
  .gallary-wrap {
    margin: 70px 0;
  }
  .filters {
    margin-top: 15px;
    button {
      margin: 0 3px;
    }
  }
  .title-container {
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
  }
  .project-title {
    font-size:16pt	
  }

  .filter.active {
    background-color: #4caf50 !important;
    color: #ffffff;
  }

  .project-item {
    transition: all .35s ease-in-out;
  }

  .projects {
    margin-bottom:50px;
    margin-top:25px;
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
  }

  /* .projects-enter {
    transform: scale(0.5) translatey(-80px);
    opacity:0;
  }

  .projects-leave-to{
    transform: translatey(30px);
    opacity:0;
  }

  .projects-leave-active {
    position: absolute;
    z-index:-1;
  } */
  .bounce-enter-active {
    animation: bounce-in .5s;
  }
  .bounce-leave-active {
    animation: bounce-in .5s reverse;
  }
  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  }

  .project {
    transition: all .35s ease-in-out;
    margin:10px;
    box-shadow:0px 2px 8px rgb(150, 150, 150);
    border-radius:3px;
    width:190px;
    height:190px;
    padding: 9px;
    display: flex;
    .prj-image {
      cursor: pointer;
    }
  }
  @media only screen and (max-width: 735px) {
    .project {
      height: 150px;
      width: 150px;
    }
  }
  @media only screen and (max-width: 635px) {
    .project {
      height: 135px;
      width: 135px;
      padding: 6px;
    }
  }
  @media only screen and (max-width: 535px) {
    .project {
      height: 115px;
      width: 115px;
      padding: 5px;
    }
  }
  @media only screen and (max-width: 435px) {
    .project {
      height: 95px;
      width: 95px;
      padding: 3px;
    }
  }
  @media only screen and (max-width: 375px) {
    .project {
      height: 75px;
      width: 75px;
      padding: 3px;
    }
  }
  .card360 {
    position: relative;
    .close {
      position: absolute;
      z-index: 103;
      right: 45px;
      top: 12px;
      height: 25px;
      width: 25px;
    }
  }
</style>
