<template>
  <div class="top-carousel">
    <v-carousel
      cycle
      class="carousel"
      :height="height"
      hide-delimiter-background
      show-arrows-on-hover
      :interval="getContent.topCarouselTime"
    >
      <v-carousel-item
        v-for="(item, i) in getContent.topCarousel"
        :key="i"
      >
        <div class="slide-item" v-html="item.html"></div>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  props:['height'],
  computed: {
    ...mapGetters(['getContent'])
  }
}
</script>

<style lang="scss" scoped>
  .top-carousel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3;
  }
  .slide-item {
    position: absolute;
    top: 23%;
    left: 7%;
    font-size: 1.3rem;
    font-weight: bold;
    background-color: rgba(0,0,0,.5);
    padding: 10px;
    border-radius: 3px;
  }
</style>
